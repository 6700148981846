import { observable, computed, action } from "mobx";
import { Injectable } from "@angular/core";
import { StatisticService } from '../services/statistic.service'
import { Subscription } from "rxjs";
import { APP_CONFIG } from "../../../constant/status.constant";

@Injectable({ providedIn: "root" })
export class PassengerStore {
    @observable data: any[] = [];
    @observable fetchSearchRef: Subscription;

    constructor(private ds: StatisticService) { }

    @action
    fetchSearch(post:any,searchOption: any) {
        const { ref } = searchOption
        switch (ref) {
            case 'vehicles':
                this.fetchSearchRef && this.fetchSearchRef.unsubscribe()
                this.fetchSearchRef = this.ds.vehiclesSearchRef(post).valueChanges().subscribe(docs => {
                    this.data = docs;
                })
                break;
            case 'passengers':
                this.fetchSearchRef && this.fetchSearchRef.unsubscribe()
                this.fetchSearchRef = this.ds.passengersSearchRef(post).valueChanges().subscribe(docs => {
                    this.data = docs;
                })
                break;
            default:
                break;
        }
    }
    @action
    searchDataRef(post:any,searchOption: any, keyword: any) {
        const { ref } = searchOption;
        return this.ds.searchRef(post,ref, keyword).valueChanges();
    }

    @action
    unsubscribeFetchSearch() {
        this.fetchSearchRef && this.fetchSearchRef.unsubscribe()
    }
}